@import '../../style/themes/index';
@import '../../style/mixins/index';

@progress-prefix-cls: ~'@{ant-prefix}-progress';

.@{progress-prefix-cls} {
  .reset-component;

  display: inline-block;

  &-line {
    position: relative;
    width: 100%;
    font-size: @font-size-base;
  }

  &-small&-line,
  &-small&-line &-text .@{iconfont-css-prefix} {
    font-size: @font-size-sm;
  }

  &-outer {
    display: inline-block;
    width: 100%;
    margin-right: 0;
    padding-right: 0;
    .@{progress-prefix-cls}-show-info & {
      margin-right: ~'calc(-2em - 8px)';
      padding-right: ~'calc(2em + 8px)';
    }
  }

  &-inner {
    position: relative;
    display: inline-block;
    width: 100%;
    overflow: hidden;
    vertical-align: middle;
    background-color: @progress-remaining-color;
    border-radius: @progress-radius;
  }

  &-circle-trail {
    stroke: @progress-remaining-color;
  }

  &-circle-path {
    animation: ~'@{ant-prefix}-progress-appear' 0.3s;
  }

  &-inner:not(.@{ant-prefix}-progress-circle-gradient) {
    .@{ant-prefix}-progress-circle-path {
      stroke: @progress-default-color;
    }
  }

  &-success-bg,
  &-bg {
    position: relative;
    background-color: @progress-default-color;
    border-radius: @progress-radius;
    transition: all 0.4s @ease-out-circ 0s;
  }

  &-success-bg {
    position: absolute;
    top: 0;
    left: 0;
    background-color: @success-color;
  }

  &-text {
    display: inline-block;
    width: 2em;
    margin-left: 8px;
    color: @text-color-secondary;
    font-size: 1em;
    line-height: 1;
    white-space: nowrap;
    text-align: left;
    vertical-align: middle;
    word-break: normal;
    .@{iconfont-css-prefix} {
      font-size: @font-size-base;
    }
  }

  &-status-active {
    .@{progress-prefix-cls}-bg::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: @component-background;
      border-radius: 10px;
      opacity: 0;
      animation: ~'@{ant-prefix}-progress-active' 2.4s @ease-out-quint infinite;
      content: '';
    }
  }

  &-status-exception {
    .@{progress-prefix-cls}-bg {
      background-color: @error-color;
    }
    .@{progress-prefix-cls}-text {
      color: @error-color;
    }
  }

  &-status-exception &-inner:not(.@{progress-prefix-cls}-circle-gradient) {
    .@{progress-prefix-cls}-circle-path {
      stroke: @error-color;
    }
  }

  &-status-success {
    .@{progress-prefix-cls}-bg {
      background-color: @success-color;
    }
    .@{progress-prefix-cls}-text {
      color: @success-color;
    }
  }

  &-status-success &-inner:not(.@{progress-prefix-cls}-circle-gradient) {
    .@{progress-prefix-cls}-circle-path {
      stroke: @success-color;
    }
  }

  &-circle &-inner {
    position: relative;
    line-height: 1;
    background-color: transparent;
  }

  &-circle &-text {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    margin: 0;
    padding: 0;
    color: @progress-text-color;
    line-height: 1;
    white-space: normal;
    text-align: center;
    transform: translate(-50%, -50%);

    .@{iconfont-css-prefix} {
      font-size: 14 / 12em;
    }
  }

  &-circle&-status-exception {
    .@{progress-prefix-cls}-text {
      color: @error-color;
    }
  }
  &-circle&-status-success {
    .@{progress-prefix-cls}-text {
      color: @success-color;
    }
  }
}

@keyframes ~"@{ant-prefix}-progress-active" {
  0% {
    width: 0;
    opacity: 0.1;
  }
  20% {
    width: 0;
    opacity: 0.5;
  }
  100% {
    width: 100%;
    opacity: 0;
  }
}

@primary-color: #B698D9;@secondary-color: #B01F24;@link-color: #1DA57A;